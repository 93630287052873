import React from "react";

import "../../App.scss";
import beforeFilterAchievements from "../../achievements";
import AchievementBadge from "./AchievementBadge";

function AchievementPage() {
  const achievements = beforeFilterAchievements;

  const DISABLE_FR_ACHIEVEMENTS = true;

  return (
    <div className="achievements">
      <h3>Achievements</h3>
      <p className="achievement-page-description">
        There are {achievements.length} achievements to be unlocked in the
        September season.
      </p>
      <div className="achievement-section">
        <div>
          <div className="achievement-container">
            {achievements
              .sort((a, b) => (a.xp > b.xp ? 1 : -1))
              .map((achievement) => {
                if (
                  achievement.enabled === false ||
                  (DISABLE_FR_ACHIEVEMENTS && achievement.fr_specific)
                )
                  return null;

                return (
                  <>
                    <AchievementBadge
                      achievement={achievement}
                      achievement_page={true}
                    />
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AchievementPage;
