import React from "react";

import "../../App.scss";
import beforeFilterAchievements from "../../achievements";
import AchievementBadge from "./AchievementBadge";

function Achievements({ player, alle_turneringer, cumScores, setXp }) {
  const achievements = beforeFilterAchievements;

  const DISABLE_FR_ACHIEVEMENTS = false;

  let xp = 0;
  let level = 1;

  player &&
    achievements
      .sort((a, b) => (a.lvlReq > b.lvlReq ? 1 : -1))
      .forEach((achievement) => {
        if (achievement.check(player, alle_turneringer, cumScores)) {
          xp += achievement.xp;
        }
      });

  setXp && setXp(xp);

  return (
    <div className="achievement-section">
      <div>
        <div className="achievement-container">
          {achievements
            .sort((a, b) => (a.xp > b.xp ? 1 : -1))
            .map((achievement) => {
              if (
                achievement.enabled === false ||
                (DISABLE_FR_ACHIEVEMENTS && achievement.fr_specific)
              )
                return null;

              return (
                <>
                  <AchievementBadge
                    achievement={achievement}
                    player={player}
                    alle_turneringer={alle_turneringer}
                    cumScores={cumScores}
                    level={level}
                  />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Achievements;
